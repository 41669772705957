import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "提供価値によって異なるデザインプロセス",
  "date": "2017-11-17T03:00:00.000Z",
  "slug": "entry/2017/11/17/120000",
  "tags": ["medley"],
  "hero": "./2017_11_17.png",
  "heroAlt": "デザインプロセス"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`最近 PS4 のグランツーリスモスポーツをやり始めて、自宅のネット環境の遅さに気づいたデザイナーの`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/49119"
      }}>{`マエダ`}</a>{`です。前回は`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/03/160000"
      }}>{`DLS について`}</a>{`ご紹介させていただきましたが、今回はメドレーに入社して感じた「デザインプロセスの違い」について自分なりにまとめてみました。`}</p>
    <p>{`あとで読みたい人向けに、エレベーターピッチ風にまとめると、`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`[ CLINICS ] というサービスは
[ 患者と医療機関向け ] それぞれサービスを提供しているが
[ 提供価値の違い ] によって
[ デザインの役割が異なる ] ことに気づいた

特に [ 医療機関向け ] は
[ UI が重要 ] となり
[ 伝えることを目的とした Web サイト ] とは違って
[ UI デザインの良し悪しがプロダクト全体の品質に関わる ] ため
[ 事業や技術を理解 ] した[ デザインオリエンテッド ] が求められる`}</code></pre></div>
    <p>{`というような内容です。`}</p>
    <h1>{`ユーザーによって異なる提供価値`}</h1>
    <p>{`メドレーに入社してから、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS（クリニクス）`}</a>{`」というサービスのデザインを主に担当しているのですが、患者と医療機関側で提供しているプロダクトの内容が異なります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171116/20171116131612.png",
      "alt": "20171116131612.png"
    }}></img>
    <ul>
      <li parentName="ul">{`オンライン診療の内容を伝え、利用を促すための Web サイト（患者・医療機関双方）`}</li>
      <li parentName="ul">{`患者がオンラインで通院を行うためのアプリ`}</li>
      <li parentName="ul">{`医療機関側がオンラインで遠隔診療を行うためのシステム`}</li>
    </ul>
    <h2>{`サービスの入り口となる Web サイト`}</h2>
    <p>{`Web ページの役割としては、オンライン診療というものがどういったもので、CLINICS を利用するとどういう課題解決につながるのか、というサービスの特徴を患者と医療機関双方に「`}<strong parentName="p">{`伝えるためのデザイン`}</strong>{`」が必要となります。`}</p>
    <p>{`デザインするにあたって注力すべきポイントは、装飾やイメージ画像などシンボリックなデザインとストーリー性のある導線設計をすることで、視覚的に特徴を伝わりやすくし、またコンバージョンポイントへ誘導するため、ボタン等は目立たせるなど、適切に情報を伝え、行動を促すためのデザインが重要になります。`}</p>
    <h2>{`患者がオンライン診療を行うためのアプリ`}</h2>
    <p>{`アプリは患者がオンライン診療をするための「病院検索」→「予約」→「問診」→「診察」→「決済」の機能をシームレスに繋げるためストレスのかからないユーザー体験を提供することが重要です。`}</p>
    <p>{`そもそもオンライン診療のメリットとして、待ち時間の軽減や、落ち着いた環境で診察ができるなどが挙げられるため、そこに至るまでのユーザー体験を台無しにしてしまう UI では元も子もなくなってしまいます。`}</p>
    <p>{`アプリでは「伝えるデザイン」よりも「`}<strong parentName="p">{`機能的なデザイン`}</strong>{`」が必要になりますが、ユーザーの行動を途切れさせないよう、不必要な要素や導線を極力排除して、非常にシンプルな UI 設計をおこなっており、機能自体を主張しないデザインを心がけています。前回このブログでもとりあげた`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/03/160000"
      }}>{`DLS`}</a>{`も、そういった設計思想のもと開発を進めていたからこそ実現できたと思っています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20171117/20171117113501.png",
      "alt": "20171117113501.png"
    }}></img>
    <h2>{`医療機関向けの遠隔診療システム`}</h2>
    <p>{`医療機関側に提供しているシステムは、オンライン診療を行うためのツールです。
Web サイトのように「伝えるデザイン」やコンバージョン重視ではなく、「`}<strong parentName="p">{`より機能的に使いやすいデザイン`}</strong>{`」が重要になります。
このような画面を Bootstrap のような UI テンプレートそのままの見た目で構築してしまうと、技術的に素晴らしいものができたとしても、使い勝手が悪く貧弱な機能と見られかねないため、`}<strong parentName="p">{`デザイナーが管理画面の UI 設計に携わることは、ビジネス的にも非常に重要な要素`}</strong>{`です。`}</p>
    <p>{`特に CLINICS の医療機関向けのシステムは、実際にオンラインで患者の診察を行うツールのため、医療機関側が診療行為をつつがなく終えられるような UI 設計が重要です。`}</p>
    <p>{`MVP 的な手法で、とりあえずリリースして検証を重ねていくというスタンスがとれないため、リリースするまでに機能的に不備がないか、医療従事者が迷わず正しく使える UI 設計になっているかなど、社内や実際の医療機関のテストを繰り返し、試行錯誤を経てリリースするという、プロダクトデザインをしている感覚になり「伝えるデザイン」とは違った思考でデザインに取り組んでいます。`}</p>
    <h1>{`機能重視なサービスこそ、直感的にシンプルな UI が重要`}</h1>
    <p>{`このように CLINICS という 1 つのサービスを構成する要素の中でも、ターゲットユーザーや提供価値の違いによって、デザインアプローチや重要視する視点が異なります。`}</p>
    <p>{`たとえば、自分も業務でよく利用するプロトタイピングツールの inVision もログイン前は、利用シーンやより魅力的なサービスであるということを伝えるためのデザインをしており、ログイン後は直感的にわかりやすいシンプルな UI 設計で、ログイン前後でサービスの UI が異なります。`}</p>
    <p>{`inVision も機能は豊富ですが、プロトタイピングを行う上で非常にシンプルな操作性を提供しており、無駄な説明や導線がなくても直感的に使える UI は、継続して利用できる安心感にもつながり、見習うべき UI だなと思っています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171116/20171116131754.png",
      "alt": "20171116131754.png"
    }}></img>
    <p>{`（inVision の画面の違い）`}</p>
    <p>{`CLINICS の医療機関向けシステムも受付管理やスケジュール、オンライン診療を行う機能など複数の機能をひとつのサービスとして提供しているため、UI 的に複雑になりかねません。複雑な機能をまとめ、画面上にシンプルに落とし込めるかどうかというのを吟味し、作っては壊し、作っては壊しを繰り返しします。`}</p>
    <p>{`これならいける！とおもった UI も、機能面での見落としなどがあったりすると導線に矛盾が生じたり、シンプルに表現したつもりが、逆に使い勝手の悪い UI になってしまったり。UI を考えるというのは、感性に訴えるデザインとは違ったよりロジカルな思考が必要で、デザインしながら四苦八苦して、ぶつぶつ独り言を言うことが多くなります w`}</p>
    <h1>{`デザインオリエンテッドなプロダクト開発`}</h1>
    <p>{`Web サービスであればいかに目標としているコンバージョン率を高めるかどうか、分析〜調査〜開発というサイクルをベースとした運用になりますが、特に`}<strong parentName="p">{`医療機関向けのシステムの場合は、ムダな機能や使いづらい UI だとサービス的に不安要素を抱かせる要因にもなり、ビジネスの成功可否に直結します`}</strong>{`。`}</p>
    <p>{`そのため、リリースまでに UI を磨けるだけ磨き、実際に医療機関の現場に出向いてどういうフローで診察を行っているのかなどヒアリングしたり、出来上がった機能を試してもらうなどし、十分に検討した上でリリースしています。こうしたデザインを重視した開発が行える点はメドレーだからこその開発体制かもしれません。`}</p>
    <p>{`このような`}<strong parentName="p">{`デザインオリエンテッドなプロダクト開発を行う上で重要なポイントは、事業理解とエンジニアとの密な連携`}</strong>{`です。表面的なデザインではなく、実際に使われる利用シーンを想像しつつも、体験することが難しい医療サービスだからこそ、前提の知識やヒアリング、ユーザーテストなどが重要となりますし、機能的な部分に関してはエンジニアと仕様について議論したり、ユースケースを踏まえてどのような UI に落とし込むべきかを考えながらデザインに落とし込んでいきます。インタラクティブな表現など inVision でも表現しきれない細かい動きや導線などは、実装時にエンジニアに伝わりやすいようフロントエンド部分のコーディングを自ら行うことでコードを通じてエンジニアとコミュニケーションが取りやすくもなるので、フロントエンドも把握しておくことは重要です。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`個人的には「伝えるデザイン」と「機能的なデザイン」で、明確に思考をわけて考えてデザインしてきたわけではなかったのですが、提供すべき価値の違いによって`}<strong parentName="p">{`左脳と右脳それぞれ使い分けてデザインしている`}</strong>{`かもしれないということに気付かされました。これは以前デザイナーの小山がブログで書いた`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/09/14/132031"
      }}>{`システム 1（自動的に直感で動く”早い思考”）とシステム 2（手動で論理的に動く”遅い思考”）`}</a>{`が自分の中で振り子のように行き来してるだろうなと感じたので、興味のある方は「思考とデザインスキル」も読んでもらうとわかりやすいです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/09/14/132031"
      }}>{`https://developer.medley.jp/entry/2017/09/14/132031`}</a></p>
    <h1>{`最後に`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/62275"
      }}>{`ふだんビールばっかり呑んで`}</a>{`適当な人とレッテルを貼られているマエダですが、今回は真面目なことを書いてみましたがいかがでしたでしょうか。このブログを書いてて正直疲れたので、システム 2 が働いてるに違いないと思います。こんな私と一緒に仕事がしたい、呑みたいというデザイナーやエンジニアさん。応募お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story.html"
      }}>{`https://www.medley.jp/team/creator-story.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      